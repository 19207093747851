import { watch, reactive, computed } from 'vue';

/**
 * Tanstack errors are internally managed and are part of the query state.
 * This composable copies them to a mutable reactive array so that they can be cleared
 * and used with ApiErrorAlert or other template conditions such as `v-if`. Whereas normally
 * the `@dismiss` event in a GlAlert for example would not be able to clear the state directly.
 *
 * Non-tanstack `ref` error states can also be managed by the composable.
 *
 * @param {Array<Ref<Error>>} errs
 * @returns Ref<Array>
 */
export const useErrors = (errs = []) => {
  const errors = reactive([]);

  watch(
    errs,
    (newErrs) => {
      newErrs.forEach((e, i) => {
        errors[i] = e;
      });
    },
    { deep: true, immediate: true },
  );

  const anyError = computed(() => errors.some((e) => Boolean(e)));

  const clear = () => {
    errors.forEach((v, i) => {
      errors[i] = null;
    });
  };

  return {
    errors,
    anyError,
    clear,
  };
};
