<script>
import { GlForm, GlFormGroup, GlFormTextarea, GlButton } from '@gitlab/ui';

export default {
  name: 'TenantForm',
  components: {
    GlForm,
    GlFormGroup,
    GlFormTextarea,
    GlButton,
  },
  props: {
    tenant: {
      type: Object,
      default: () => {},
    },
    withFile: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Configuration JSON',
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['submit', 'error'],

  data() {
    let jsonText = '';
    if (this.value) jsonText = JSON.stringify(this.value, null, 2);

    return {
      jsonText,
      busy: false,
    };
  },
  computed: {
    buttonCaption() {
      if (this.buttonText) return this.buttonText;

      const action = this.tenant ? 'Update' : 'Create';
      return `${action} Tenant`;
    },
  },
  methods: {
    submit() {
      if (this.jsonIsValid()) {
        this.$emit('submit', this.jsonText);
      } else {
        this.$emit('error', 'Invalid JSON');
      }
    },

    jsonIsValid() {
      if (!this.required && this.jsonText.length === 0) return true;

      try {
        JSON.parse(this.jsonText);
        return true;
      } catch (_) {
        return false;
      }
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = (e) => {
        this.jsonText = e.target.result;
      };
    },
  },
};
</script>

<template>
  <gl-form class="tenant-form" @submit.prevent="submit">
    <gl-form-group :label="label" label-for="json-text">
      <gl-form-textarea
        id="json-text"
        v-model="jsonText"
        data-testid="json-input"
        :rows="30"
        placeholder="Enter JSON"
        :required="required"
      />
    </gl-form-group>

    <gl-form-group v-if="withFile" label="Configuration File" label-for="json-file">
      <input id="json-file" type="file" @change="onFileChange" />
    </gl-form-group>

    <div class="gl-flex">
      <gl-button variant="confirm" type="submit" :loading="busy">
        {{ buttonCaption }}
      </gl-button>
    </div>
  </gl-form>
</template>
