<script setup>
import { computed, ref } from 'vue';
import { GlAlert, GlSprintf, GlLink } from '@gitlab/ui';
import { useRoute } from 'vue-router';
import ApiErrorAlert from '~/common/api_error_alert.vue';
import { updateOverride } from '~/api/tenants';
import TenantForm from '~/pages/tenants/shared/tenant_json_form.vue';
import { ROUTER_VARS } from '~/pages/spa/routes/constants';
import { useTenantQuery } from '~/queries/use_tenant_query';
import { useErrors } from '~/composables/use_errors';
import { isValidAPIError } from '~/helpers/error_utilities';

const props = defineProps({
  tenantId: {
    type: [String, Number],
    default: null,
  },
});

const route = useRoute();
const computedTenantId = computed(() => props.tenantId || route?.params[ROUTER_VARS.TENANT_ID]);

const { data: tenant, error: tenantError } = useTenantQuery({ tenantId: computedTenantId });

const apiError = ref(null);
const { errors, anyError, clear } = useErrors([tenantError, apiError]);

const save = async (output_override) => {
  try {
    await updateOverride(tenant.value.id, { output_override });
    window.location = `/tenants/${tenant.value.id}?override_success=1`;
  } catch (e) {
    if (!isValidAPIError(e)) {
      throw e;
    }
    apiError.value = e.response.data;
  }
};

const alertFail = (message) => {
  apiError.value = { message };
};
</script>

<template>
  <div>
    <api-error-alert v-if="anyError" :error="errors" @dismiss="clear" />

    <gl-alert class="gl-mb-5" :dismissible="false">
      <gl-sprintf message="JSON overrides uses %{linkStart}deep_merge%{linkEnd} internally.">
        <template #link="{ content }">
          <gl-link
            href="https://api.rubyonrails.org/classes/Hash.html#method-i-deep_merge"
            target="_blank"
          >
            {{ content }}
          </gl-link>
        </template>
      </gl-sprintf>
      <ul>
        <li>All overrides are in addition to the existing Tenant JSON.</li>
        <li>To remove an attribute, set it's value to null</li>
      </ul>
      <gl-sprintf
        message="See examples of how this works in the Switchboard %{linkStart}runbook%{linkEnd}"
      >
        <template #link="{ content }">
          <gl-link
            href="https://gitlab.com/gitlab-com/gl-infra/gitlab-dedicated/incident-management/-/blob/main/runbooks/switchboard.md#json-overrides"
            target="_blank"
          >
            {{ content }}
          </gl-link>
        </template>
      </gl-sprintf>
    </gl-alert>

    <tenant-form
      v-if="tenant"
      :value="tenant.output_override"
      label="Configuration JSON Override"
      button-text="Save"
      @error="alertFail"
      @submit="save"
    />
  </div>
</template>
